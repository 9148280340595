package theme

import androidx.compose.runtime.Composable
import androidx.compose.ui.text.font.FontFamily
import gsb_kundalibhagya_website.composeapp.generated.resources.Res
import gsb_kundalibhagya_website.composeapp.generated.resources.inter_bold
import gsb_kundalibhagya_website.composeapp.generated.resources.inter_light
import gsb_kundalibhagya_website.composeapp.generated.resources.inter_medium
import gsb_kundalibhagya_website.composeapp.generated.resources.inter_regular
import gsb_kundalibhagya_website.composeapp.generated.resources.inter_semibold
import org.jetbrains.compose.resources.Font

@Composable
fun InterRegular() = FontFamily(Font(Res.font.inter_regular))
@Composable
fun InterSemiBold() = FontFamily(Font(Res.font.inter_semibold))
@Composable
fun InterMedium() = FontFamily(Font(Res.font.inter_medium))
@Composable
fun InterLight() = FontFamily(Font(Res.font.inter_light))
@Composable
fun InterBold() = FontFamily(Font(Res.font.inter_bold))